import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/Home')
  },
  {
    path: '/join',
    component: () => import('../views/Join')
  },
  {
    path: '/roadmap',
    component: () => import('../views/Roadmap')
  },
  {
    path: '/faq',
    component: () => import('../views/Faq')
  },
  {
    path: '/cities',
    component: () => import('../views/Cities')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savePosition) {
    if(to.query.tag !== 'aboutUs') {
      return {
        y: 0
      }
    }
  }
})

export default router
