import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import './assets/css/base.css'
import 'nprogress/nprogress.css'

import { Button, Popup, Collapse, CollapseItem, Toast, Pagination, Icon, Swipe, SwipeItem } from 'vant'

Vue.use(Button).use(Popup).use(Collapse).use(CollapseItem).use(Toast).use(Pagination)
    .use(Icon).use(Swipe).use(SwipeItem)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
